// GET USERS EMAILS LIST
export const SET_ADMIN_FILTERS_TRANSACTIONS = 'SET_ADMIN_FILTERS_TRANSACTIONS';
export const SET_ADMIN_FILTERS_USERS = 'SET_ADMIN_FILTERS_USERS';
export const SET_ADMIN_FILTERS_PRICE_CHART = 'SET_ADMIN_FILTERS_PRICE_CHART';

export const setAdminFiltersTransactions = payload => ({
  type: SET_ADMIN_FILTERS_TRANSACTIONS,
  payload,
});

export const setAdminFiltersUsers = payload => ({
  type: SET_ADMIN_FILTERS_USERS,
  payload,
});

export const setAdminFiltersPriceChart = payload => ({
  type: SET_ADMIN_FILTERS_PRICE_CHART,
  payload,
});
