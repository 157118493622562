import React from 'react';
import { Row, Col } from 'antd';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';

const BackupPhraseStepThree = ({ okButtonAction }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="modal-backup__success-message">{t('profile.backupPhraseSuccess')}</div>

      <Row className="modal__buttons-wrapper modal-backup__buttons-wrapper">
        <Col span="24">
          <Button type="primary" className="modal__button modal-backup__button-ok" onClick={okButtonAction}>
            {t('done')}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BackupPhraseStepThree;
