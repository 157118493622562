import {
  //--GET CHECKOUTS DATA
  GET_CHECKOUT_DATA_REQUEST,
  GET_CHECKOUT_DATA_SUCCESS,
  GET_CHECKOUT_DATA_FAIL,
  //--GET CHECKOUT DATA
  CREATE_CHARGE_ID_REQUEST,
  CREATE_CHARGE_ID_SUCCESS,
  CREATE_CHARGE_ID_RESET,
  CREATE_CHARGE_ID_FAIL,
  SET_CHARGE_ID,
  //--GET SALE TOKEN CHECKOUT DATA
  CREATE_SALE_TOKEN_CHARGE_ID_REQUEST,
  CREATE_SALE_TOKEN_CHARGE_ID_SUCCESS,
  CREATE_SALE_TOKEN_CHARGE_ID_RESET,
  CREATE_SALE_TOKEN_CHARGE_ID_FAIL,
  SET_SALE_TOKEN_CHARGE_ID,
  //--GET PAYMENT DATA
  GET_CHARGE_DATA_REQUEST,
  GET_CHARGE_DATA_SUCCESS,
  GET_CHARGE_DATA_RESET,
  GET_CHARGE_DATA_FAIL,
  //--GET SALE TOKEN PAYMENT DATA
  GET_SALE_TOKEN_CHARGE_DATA_REQUEST,
  GET_SALE_TOKEN_CHARGE_DATA_SUCCESS,
  GET_SALE_TOKEN_CHARGE_DATA_RESET,
  GET_SALE_TOKEN_CHARGE_DATA_FAIL,
} from '../actions/checkoutPayment';

const defaultState = {
  getCheckoutData: { data: null, fetching: false, error: null },
  chargeId: { data: null, fetching: false, error: null },
  getCharge: { data: null, fetching: false, error: null },
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_CHECKOUT_DATA_REQUEST:
      return {
        ...state,
        getCheckoutData: { ...state.getCheckoutData, fetching: true },
      };
    case GET_CHECKOUT_DATA_SUCCESS:
      return {
        ...state,
        getCheckoutData: { ...state.getCheckoutData, fetching: false, data: action.payload.data, error: null },
      };
    case GET_CHECKOUT_DATA_FAIL:
      return {
        ...state,
        getCheckoutData: { ...state.getCheckoutData, error: action.payload, fetching: false },
      };
    case CREATE_CHARGE_ID_REQUEST:
    case CREATE_SALE_TOKEN_CHARGE_ID_REQUEST:
      return {
        ...state,
        chargeId: { ...state.chargeId, fetching: true },
      };
    case CREATE_CHARGE_ID_SUCCESS:
    case CREATE_SALE_TOKEN_CHARGE_ID_SUCCESS:
      return {
        ...state,
        chargeId: { ...state.chargeId, fetching: false, data: action.payload.data.id },
      };
    case CREATE_CHARGE_ID_RESET:
    case CREATE_SALE_TOKEN_CHARGE_ID_RESET:
      return {
        ...state,
        chargeId: { data: null, fetching: false, error: null },
      };
    case CREATE_CHARGE_ID_FAIL:
    case CREATE_SALE_TOKEN_CHARGE_ID_FAIL:
      return {
        ...state,
        chargeId: { ...state.chargeId, fetching: false, error: true },
      };
    case SET_CHARGE_ID:
    case SET_SALE_TOKEN_CHARGE_ID:
      return {
        ...state,
        chargeId: { ...state.chargeId, data: action.payload },
      };
    case GET_CHARGE_DATA_REQUEST:
    case GET_SALE_TOKEN_CHARGE_DATA_REQUEST:
      return {
        ...state,
        getCharge: { ...state.getCharge, fetching: true },
      };
    case GET_CHARGE_DATA_SUCCESS:
    case GET_SALE_TOKEN_CHARGE_DATA_SUCCESS:
      return {
        ...state,
        getCharge: { ...state.getCharge, fetching: false, data: action.payload.data },
      };
    case GET_CHARGE_DATA_RESET:
    case GET_SALE_TOKEN_CHARGE_DATA_RESET:
      return {
        ...state,
        getCharge: { ...state.getCharge, data: null, fetching: false, error: null },
      };
    case GET_CHARGE_DATA_FAIL:
    case GET_SALE_TOKEN_CHARGE_DATA_FAIL:
      return {
        ...state,
        getCharge: { ...state.getCharge, fetching: false, error: true },
      };
    default:
      return state;
  }
}
