import {
    GET_ADMIN_TRANSACTIONS_BY_USER_REQUEST,
    GET_ADMIN_TRANSACTIONS_BY_USER_SUCCESS,
    GET_ADMIN_TRANSACTIONS_BY_USER_MORE_REQUEST,
    GET_ADMIN_TRANSACTIONS_BY_USER_MORE_SUCCESS
  } from '../../actions/adminPanel/adminTransactionsByUser';
  
  export default function reducer(
    state = {
      entities: [],
      page: 1,
      pages: 0,
      countItem: 0,
      fetching: false,
      fetchingMore: false
    },
    action
  ) {
    switch (action.type) {
      case GET_ADMIN_TRANSACTIONS_BY_USER_REQUEST:
        return {
          ...state,
          fetching: true,
        };
      case GET_ADMIN_TRANSACTIONS_BY_USER_SUCCESS:
        return {
          ...state,
          ...action.payload,
          fetching: false,
        };
        case GET_ADMIN_TRANSACTIONS_BY_USER_MORE_REQUEST:
      return {
        ...state,
        fetchingMore: true,
      };
      case GET_ADMIN_TRANSACTIONS_BY_USER_MORE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        entities: [...state.entities, ...action.payload.entities],
        fetchingMore: false,
      };
      default:
        return state;
    }
  }
  