import * as React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Label } from 'recharts';

import { curveCardinal } from 'd3-shape';

const valuesYAxisFormatter = number => {
  if (number.toString().length > 3 && number > 999) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  if (number.toString().length > 5 && number < 999) {
    return number.toFixed(4);
  }

  return number;
};

const Graph = ({ data, dataKey, labelForXAxis }) => {
  const cardinal = curveCardinal.tension(0.2);

  const maxValueFromDataArray = Math.max(...data.map(el => el[dataKey]));
  const SumMaxValueFromDataArray = Math.round(maxValueFromDataArray + maxValueFromDataArray);

  const roundYAxisValue = value => {
    if (value.toString().length > 7 && value.toString().length < 10) {
      const roundToInteger = value => Math.floor(value / 10000000) * 10000000;
      return roundToInteger(value);
    }
    if (value.toString().length === 7) {
      const roundToInteger = value => Math.floor(value / 1000000) * 1000000;
      return roundToInteger(value);
    }
    if (value.toString().length === 6) {
      const roundToInteger = value => Math.floor(value / 100000) * 100000;
      return roundToInteger(value);
    }
    if (value.toString().length === 5) {
      const roundToInteger = value => Math.floor(value / 10000) * 10000;
      return roundToInteger(value);
    }
    if (value.toString().length === 4) {
      const roundToInteger = value => Math.floor(value / 1000) * 1000;
      return roundToInteger(value);
    }
    if (value.toString().length === 3) {
      const roundToInteger = value => Math.floor(value / 100) * 100;
      return roundToInteger(value);
    }
    if (value.toString().length === 2) {
      const roundToInteger = value => Math.floor(value / 10) * 10;
      return roundToInteger(value);
    }
    if (value.toString().length === 1) {
      return Math.ceil(value);
    }
  };

  return (
    <AreaChart
      width={900}
      height={500}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <defs>
        <linearGradient id="colorUv" x1="0" y1="100%" x2="0" y2="0" spreadMethod="reflect">
          <stop offset="0%" stopColor="#F99400" stopOpacity={0} />
          <stop offset="100%" stopColor="#F99400" stopOpacity={0.2} />
        </linearGradient>
      </defs>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="time" interval={0} tickMargin={10} padding={{ right: 10 }}>
        <Label value="DATES" dy={25} />
      </XAxis>
      <YAxis
        label={{
          value: dataKey.toUpperCase().replace(/T([^\s])/g, 'T $1'),
          angle: -90,
          position: 'insideLeft',
          dx: -30,
        }}
        tickMargin={5}
        domain={[0, roundYAxisValue(SumMaxValueFromDataArray)]}
        tickFormatter={valuesYAxisFormatter}
      />

      <Tooltip formatter={value => new Intl.NumberFormat('en').format(value)} />
      <Area type={cardinal} dataKey={dataKey} stroke="#F99400" fill="url(#colorUv)" />
    </AreaChart>
  );
};

export default Graph;
