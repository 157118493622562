export const translateError = (t, text) => {
  let error = '';

  if (typeof text === 'string') {
    text = text.split(' ');
  }

  if (text[7] === 'wallet') {
    error = t('swap.errors.walletBalance', {
      number: text[10],
      amountFrom: text[17],
      amountFromCurrency: text[18],
      replenishAmount: text[34],
      replenishAmountCurrency: text[35],
      amountTo: text[43],
      amountToCurrency: text[44],
    });
  } else {
    error = t('swap.errors.minerFee', {
      amount: text[4],
      amountCurrency: text[5],
      walletAmount: text[text.length - 2],
      walletAmountCurrency: text[text.length - 1],
    });
  }

  return error.split('/');
};

export const extractAmountFromErrorMsg=(text,setSwapAmountFromError)=>{
  if (typeof text === 'string') {
   const textArray = text.split(' ');
  if(textArray[7]=== 'wallet') setSwapAmountFromError(textArray[17]);
  //  setSwapAmountFromError(textArray[4]);
  }

  return;
}
