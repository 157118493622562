import {
  GET_ADMIN_USERS_EMAILS_REQUEST,
  GET_ADMIN_USERS_EMAILS_SUCCESS,
} from '../../actions/adminPanel/adminUsersEmailsDownload';

export default function reducer(
  state = {
    fetching: false,
    error: null
  },
  action
) {
  switch (action.type) {
    case GET_ADMIN_USERS_EMAILS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_ADMIN_USERS_EMAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
}
