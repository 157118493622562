import React from 'react';
import './style.scss';

const SwapNewCard = ({ title, children, className, buttonComponent }) => {
  return (
    <div className={`swap-new-card ${className}`}>
      <div className="swap-new-card__header">
        <div className="swap-new-card__title">{title}</div>
        {buttonComponent}
      </div>
      {children}
    </div>
  );
};

export default SwapNewCard;
