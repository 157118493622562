import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const ScrollToTop = ({ merchantId, location }) => {
  useEffect(() => {
    if (!location.pathname || !merchantId) return;

    const contentWrap = document.querySelector('.main-layout__content-wrap');
    contentWrap && document.querySelector('.main-layout__content-wrap').scrollTo(0, 0);
  }, [merchantId, location.pathname]);

  return null;
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
});

export default withRouter(connect(mapStateToProps)(ScrollToTop));
