import { RELEASE_NOTES_OPEN, RELEASE_NOTES_CLOSE } from '../actions/releaseNotes';

export default function reducer(state = null, action) {
  switch (action.type) {
    case RELEASE_NOTES_OPEN:
    case RELEASE_NOTES_CLOSE:
      return action.payload;
    default:
      return state;
  }
}
