import { NETWORK_FILTER_SET } from '../actions/networkFilter';

export default function reducer(state = localStorage.getItem('cpayNetworkFilter') || 'mainnet', action) {
  switch (action.type) {
    case NETWORK_FILTER_SET:
      return action.payload;
    default:
      return state;
  }
}
