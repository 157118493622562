import { CURRENCIES_GET, CURRENCIES_SET, CURRENCIES_ERROR } from '../actions/currencies';

export default function reducer(
  state = {
    data: [],
    fetching: false,
    page: 1,
    pages: null,
  },
  action
) {
  switch (action.type) {
    case CURRENCIES_GET:
      return {
        ...state,
        fetching: action.fetching,
      };
    case CURRENCIES_SET:
      return {
        ...state,
        data: action.payload.currencies,
        page: action.payload.page,
        pages: action.payload.pages,
        fetching: action.fetching,
      };
    case CURRENCIES_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
