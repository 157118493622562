import React from 'react';
import { Helmet } from "react-helmet";
import SwapHistory from '../components/SwapHistory';

const Swap = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <SwapHistory />
    </>)

};

export default Swap;
