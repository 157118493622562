import {
  GET_ADMIN_FEE_TRANSACTIONS_REQUEST,
  GET_ADMIN_FEE_TRANSACTIONS_SUCCESS,
  GET_ADMIN_FEE_TRANSACTIONS_MORE_REQUEST,
  GET_ADMIN_FEE_TRANSACTIONS_MORE_SUCCESS,
} from '../../actions/adminPanel/adminFeeTransactions';

export default function reducer(
  state = {
    entities: [],
    page: 1,
    pages: 0,
    countItem: 0,
    fetching: false,
    fetchingMore: false,
  },
  action
) {
  switch (action.type) {
    case GET_ADMIN_FEE_TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_ADMIN_FEE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
      };
    case GET_ADMIN_FEE_TRANSACTIONS_MORE_REQUEST:
      return {
        ...state,
        fetchingMore: true,
      };
    case GET_ADMIN_FEE_TRANSACTIONS_MORE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        entities: [...state.entities, ...action.payload.entities],
        fetchingMore: false,
      };
    default:
      return state;
  }
}
