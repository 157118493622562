import { MAIN_WALLETS_GET, MAIN_WALLETS_SET, MAIN_WALLETS_ERROR } from '../actions/mainWallets';

export default function reducer(
  state = {
    data: [],
    fetching: false,
  },
  action
) {
  switch (action.type) {
    case MAIN_WALLETS_GET:
      return {
        ...state,
        fetching: true,
      };
    case MAIN_WALLETS_SET:
      return {
        ...state,
        data: action.payload,
        fetching: false,
      };
    case MAIN_WALLETS_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
