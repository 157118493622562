import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';

const MultisendRecipients = ({ currency, setText, data, setData, handleEstimate, setShowConfirm }) => {
  const { t } = useTranslation();

  return (
    <div className="multisend-recipients">
      <div className="multisend-recipients__title">{t('multisendWithdraw.listOfRecipients')}</div>
      <div className="multisend-recipients__table">
        <div className="multisend-recipients__table-labels">
          <Row>
            <Col span={14}>
              <span>{t('multisendWithdraw.addresses')}</span>
            </Col>
            <Col span={10}>
              <span>{t('amount')}</span>
            </Col>
          </Row>
        </div>
        <div className="multisend-recipients__table-data-wrapper">
          {data.map((dataItem, index) => (
            <div key={dataItem[0]} className="multisend-recipients__table-data">
              <Row>
                <Col span={24} md={14}>
                  <span>{dataItem[0]}</span>
                </Col>
                <Col span={24} md={8}>
                  <span>
                    {dataItem[1]} {currency.title}
                  </span>
                </Col>
                <Col span={24} md={2}>
                  <div
                    className="multisend-recipients__table-data-remove"
                    onClick={() => {
                      const updatedData = [...data];
                      updatedData.splice(index, 1);
                      setData(updatedData);
                      setText(updatedData.map(item => item.join(';')).join('\n'));
                      if (updatedData.length) {
                        handleEstimate(updatedData);
                      } else {
                        setShowConfirm(false);
                      }
                    }}
                  >
                    {t('multisendWithdraw.remove')}
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultisendRecipients;
