export  const formValidate = (setError,form,errorInitialState) => {
    const { dateForFiltering, currencyId } = form?.getFieldsValue(true);

    if ((dateForFiltering?.length < 2 || !dateForFiltering) && !currencyId) {
      setError(prev => ({ ...prev, dateForFiltering: true, currencyId: true }));
      return false;
    }
    if (dateForFiltering?.length < 2 || !dateForFiltering) {
      setError(prev => ({ ...prev, dateForFiltering: true }));
      return false;
    }

    if (!currencyId) {
      setError(prev => ({ ...prev, currencyId: true }));
      return false;
    }

    setError({ ...errorInitialState });
    return true;
  };
