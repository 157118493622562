// CONSTANTS
export const NOTIFICATIONS_SUCCESS_SHOW = 'NOTIFICATIONS_SUCCESS_SHOW';
export const NOTIFICATIONS_SUCCESS_HIDE = 'NOTIFICATIONS_SUCCESS_HIDE';
export const NOTIFICATIONS_ERROR_SHOW = 'NOTIFICATIONS_ERROR_SHOW';
export const NOTIFICATIONS_ERROR_HIDE = 'NOTIFICATIONS_ERROR_HIDE';

// ACTIONS GENERATORS
export const notificationsSuccessShow = success => ({
  type: NOTIFICATIONS_SUCCESS_SHOW,
  success,
});

export const notificationsSuccessHide = () => ({
  type: NOTIFICATIONS_SUCCESS_HIDE,
});

export const notificationsErrorShow = error => ({
  type: NOTIFICATIONS_ERROR_SHOW,
  error,
});

export const notificationsErrorHide = () => ({
  type: NOTIFICATIONS_ERROR_HIDE,
});
