const passwordStrength = password => {
  const lowerCaseRegex = '(?=.*[a-z])';
  const upperCaseRegex = '(?=.*[A-Z])';
  const symbolsRegex = '(?=.*[!@#$%^&*])';
  const numericRegex = '(?=.*[0-9])';

  let strength = {
    id: null,
    value: null,
    length: null,
    contains: [],
  };

  // Default
  let passwordContains = [];

  if (new RegExp(`^${lowerCaseRegex}`).test(password)) {
    passwordContains = [
      ...passwordContains,
      {
        message: 'lowercase',
      },
    ];
  }

  if (new RegExp(`^${upperCaseRegex}`).test(password)) {
    passwordContains = [
      ...passwordContains,
      {
        message: 'uppercase',
      },
    ];
  }

  if (new RegExp(`^${symbolsRegex}`).test(password)) {
    passwordContains = [
      ...passwordContains,
      {
        message: 'symbol',
      },
    ];
  }

  if (new RegExp(`^${numericRegex}`).test(password)) {
    passwordContains = [
      ...passwordContains,
      {
        message: 'number',
      },
    ];
  }

  const strongHighRegex = new RegExp(`^${lowerCaseRegex}${upperCaseRegex}${numericRegex}${symbolsRegex}(?=.{15,})`);

  const strongLowRegex = new RegExp(`^${lowerCaseRegex}${upperCaseRegex}${numericRegex}${symbolsRegex}(?=.{12,})`);

  const mediumRegex = new RegExp(
    `^((${lowerCaseRegex}${upperCaseRegex}${symbolsRegex})|(${lowerCaseRegex}${upperCaseRegex}${numericRegex})|(${lowerCaseRegex}${symbolsRegex}${numericRegex})|(${upperCaseRegex}${symbolsRegex}${numericRegex}))(?=.{8,})`
  );

  const weakHighRegex = new RegExp(
    `^((${lowerCaseRegex})|(${upperCaseRegex})|(${numericRegex})|(${symbolsRegex}))(?=.{6,})`
  );

  if (!password) {
    strength = {
      id: 0,
      value: '',
    };
  } else if (strongHighRegex.test(password)) {
    strength = {
      id: 5,
      value: 'Secure password',
    };
  } else if (strongLowRegex.test(password)) {
    strength = {
      id: 4,
      value: 'Secure password',
    };
  } else if (mediumRegex.test(password)) {
    strength = {
      id: 3,
      value: 'Medium password',
    };
  } else if (weakHighRegex.test(password)) {
    strength = {
      id: 2,
      value: 'Weak password',
    };
  } else {
    strength = {
      id: 1,
      value: 'Weak password',
    };
  }
  strength.length = password.length;
  strength.contains = passwordContains;
  return strength;
};

export default passwordStrength;
