import {
  SET_ADMIN_FILTERS_TRANSACTIONS,
  SET_ADMIN_FILTERS_USERS,
  SET_ADMIN_FILTERS_PRICE_CHART,
} from '../../actions/adminPanel/adminPageFilters';

export default function reducer(
  state = {
    transactions: null,
    priceChart: null,
    users: null,
  },
  action
) {
  switch (action.type) {
    case SET_ADMIN_FILTERS_TRANSACTIONS:
      return {
        ...state,
        transactions: { ...state.transactions, ...action.payload },
      };

    case SET_ADMIN_FILTERS_PRICE_CHART:
      return {
        ...state,
        priceChart: { ...state.priceChart, ...action.payload },
      };

    case SET_ADMIN_FILTERS_USERS:
      return {
        ...state,
        users: { ...state.users, ...action.payload },
      };

    default:
      return state;
  }
}
