import transakSDK from '@transak/transak-sdk';

export default function TransakOpen() {
  let transak = new transakSDK({
    apiKey: '1eb2d240-8bd8-4555-94a5-372c4a73ec69',
    environment: 'PRODUCTION',
    widgetWidth: '100%',
    widgetHeight: '625px'
  });

  transak.init();

  transak.on(transak.ALL_EVENTS, (data) => {
    console.log(data);
  });

  transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData) => {
    transak.close();
  });

  transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
    console.log(orderData);
    transak.close();
  });
}