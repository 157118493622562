import { adminApi } from '../../../service/admin-api';
import { notificationsErrorShow } from '../notifications';

// GET SYSTEM FEE TRANSACTIONS

export const GET_ADMIN_USER_TRANSACTIONS_REQUEST = 'GET_ADMIN_USER_TRANSACTIONS_REQUEST';
export const GET_ADMIN_USER_TRANSACTIONS_SUCCESS = 'GET_ADMIN_USER_TRANSACTIONS_SUCCESS';
export const GET_ADMIN_USER_TRANSACTIONS_MORE_REQUEST = 'GET_ADMIN_USER_TRANSACTIONS_MORE_REQUEST';
export const GET_ADMIN_USER_TRANSACTIONS_MORE_SUCCESS = 'GET_ADMIN_USER_TRANSACTIONS_MORE_SUCCESS';

export const getAdminUserTransactionsRequest = () => ({
  type: GET_ADMIN_USER_TRANSACTIONS_REQUEST,
});

export const getAdminUserTransactionsSuccess = payload => ({
  type: GET_ADMIN_USER_TRANSACTIONS_SUCCESS,
  payload,
});

export const getAdminUserTransactionsMoreRequest = () => ({
  type: GET_ADMIN_USER_TRANSACTIONS_MORE_REQUEST,
});

export const getAdminUserTransactionsMoreSuccess = payload => ({
  type: GET_ADMIN_USER_TRANSACTIONS_MORE_SUCCESS,
  payload,
});

export const getUserTransactions =
  (body = {}) =>
  async dispatch => {
    dispatch(getAdminUserTransactionsRequest());

    try {
      const response = await adminApi.getUserTransactions({
        ...body,
        search: body.search ? body.search.trim() : '',
        dateRange: undefined,
        formattedDate:undefined,
        from: body?.formattedDate ? body.formattedDate[0] : undefined,
        to: body?.formattedDate ? body.formattedDate[1] : undefined,
        order: body.order || 'DESC',
        limit: 5,
      });
      dispatch(getAdminUserTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(notificationsErrorShow(error.data));
    }
  };

export const getMoreUserTransactions =
  (body = {}) =>
  async dispatch => {
    dispatch(getAdminUserTransactionsMoreRequest());

    try {
      const response = await adminApi.getUserTransactions({
        ...body,
        search: body.search ? body.search.trim() : '',
        dateRange: undefined,
        formattedDate:undefined,
        from: body?.formattedDate ? body.formattedDate[0] : undefined,
        to: body?.formattedDate ? body.formattedDate[1] : undefined,
        order: body.order || 'DESC',
        limit: 5,
      });
      dispatch(getAdminUserTransactionsMoreSuccess(response.data));
    } catch (error) {
      dispatch(notificationsErrorShow(error.data));
    }
  };
