import { useState, useEffect } from 'react';

function metamaskAailabilityHook() {
  const { detect } = require('detect-browser');
  const { browser } = detect();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentBrowser, setCurrentBrowser] = useState(() => browser?.name);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [metamaskAuthIsShown, setMetamaskIsShown] = useState(true);

  const suportedBrowsersForMetamask = ['chrome', 'firefox', 'opera', 'edge', 'edge-chromium'];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setCurrentBrowser(detect().name);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setMetamaskIsShown(suportedBrowsersForMetamask.includes(currentBrowser));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBrowser]);


  return { metamaskAuthIsShown };
}

export default metamaskAailabilityHook;
