import {
  NOTIFICATIONS_SUCCESS_SHOW,
  NOTIFICATIONS_SUCCESS_HIDE,
  NOTIFICATIONS_ERROR_SHOW,
  NOTIFICATIONS_ERROR_HIDE,
} from '../actions/notifications';

export default function reducer(
  state = {
    successData: null,
    errorData: null,
  },
  action
) {
  switch (action.type) {
    case NOTIFICATIONS_SUCCESS_SHOW:
      return {
        ...state,
        successData: action.success,
      };
    case NOTIFICATIONS_SUCCESS_HIDE:
      return {
        ...state,
        successData: null,
      };
    case NOTIFICATIONS_ERROR_SHOW:
      return {
        ...state,
        errorData: action.error,
      };
    case NOTIFICATIONS_ERROR_HIDE:
      return {
        ...state,
        errorData: null,
      };
    default:
      return state;
  }
}
