const detectProvider = () => {
  const provider = window?.ethereum;

  return provider && provider.isMetaMask ? true : false;
};

const connectToMetamask = async (provider, setCurrentAccount, notificationsErrorShow, message, setBtnClicked) => {
  try {
    const accounts = await provider.request({
      method: 'eth_requestAccounts',
    });
  
    const account = accounts[0];
    setCurrentAccount(account);
  }catch(err){
    notificationsErrorShow({message});
    setBtnClicked(false);
    console.log(err);
  }

};

const verifyMessage = async (message, signature, ethers, currentAccount, notificationsErrorShow) => {
  try {
    const signerAddr = await ethers.utils.verifyMessage(message, signature);

    return signerAddr === currentAccount ? true : false;
  } catch (err) {
    notificationsErrorShow({ message: err });
    return false;
  }
};

const sendMetamaskRequest = (
  signature,
  currentAccount,
  verifiedAddress,
  message,
  ethers,
  notificationsErrorShow,
  authOperation,
  metamaskAuth,
  setIsSigned,
  metamaskConnected,
  installMetamask,
  removeMetamask
) => {
  if (signature && currentAccount) {
    if (currentAccount === verifiedAddress.toLowerCase()) {
      if (verifyMessage(message, signature, ethers, currentAccount, notificationsErrorShow)) {
        if (authOperation === 'login' || authOperation === 'sign-up') {
          metamaskAuth();
          setIsSigned(false);
        }

        if (authOperation === 'profile' && !metamaskConnected) {
          installMetamask(currentAccount, signature);
          setIsSigned(false);
        }

        if (authOperation === 'profile' && metamaskConnected) {
          removeMetamask(currentAccount, signature);
          setIsSigned(false);
        }

        return;
      }
    }

    notificationsErrorShow({ message: 'Smth went wrong. Please, check the address and try to sign the message again' });
    setIsSigned(false);
    return;
  }
};



export default {
  detectProvider,
  connectToMetamask,
  verifyMessage,
  sendMetamaskRequest,
};
