import {
  GET_ADMIN_SETTINGS_REQUEST,
  GET_ADMIN_SETTINGS_SUCCESS,
  UPDATE_ADMIN_SETTINGS_REQUEST,
  UPDATE_ADMIN_SETTINGS_SUCCESS,
  UPDATE_ADMIN_SETTINGS_FAIL,
} from '../../actions/adminPanel/adminSettings';

export default function reducer(
  state = {
    data: {},
    fetching: false,
  },
  action
) {
  switch (action.type) {
    case GET_ADMIN_SETTINGS_REQUEST:
    case UPDATE_ADMIN_SETTINGS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_ADMIN_SETTINGS_SUCCESS:
    case UPDATE_ADMIN_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
      };
    case UPDATE_ADMIN_SETTINGS_FAIL:
      return {
        ...state,
        fetching: false
      };
    default:
      return state;
  }
}
