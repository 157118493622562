export const RELEASE_NOTES_OPEN = 'RELEASE_NOTES_SET';
export const RELEASE_NOTES_CLOSE = 'RELEASE_NOTES_CLOSE';

export const releaseNotesOpen = release => ({
  type: RELEASE_NOTES_OPEN,
  payload: release,
});

export const releaseNotesClose = () => ({
  type: RELEASE_NOTES_CLOSE,
  payload: null,
});

export const showReleaseNotes =
  dispatch =>
  (release = null) =>
    dispatch(releaseNotesOpen(release));

export const hideReleaseNotes = dispatch => () => dispatch(releaseNotesClose());
