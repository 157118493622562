import React from 'react';
import { Pagination as PaginationAntd } from 'antd';

const Pagination = ({ current, total, getEntities, getFormValues }) => {
  const handleChange = (page, limit) => {
    getEntities({ ...getFormValues(), page, limit });
  };

  return (
    <div className="admin-users__pagination">
      <PaginationAntd
        size="small"
        current={current}
        total={total}
        pageSizeOptions={[10, 20, 50, 100]}
        onChange={handleChange}
        onShowSizeChange={handleChange}
      />
    </div>
  );
};

export default Pagination;
