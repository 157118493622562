export const NETWORK_FILTER_SET = 'NETWORK_FILTER_SET';

export const networkFilterSet = value => ({
  type: NETWORK_FILTER_SET,
  payload: value,
});

export const changeNetworkFilter = dispatch => value => {
  localStorage.setItem('cpayNetworkFilter', value);
  dispatch(networkFilterSet(value));
};
