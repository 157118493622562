import {
    GET_ADMIN_CURRENCY_PRICE_REQUEST,
    GET_ADMIN_CURRENCY_PRICE_SUCCESS,

  } from '../../actions/adminPanel/adminCurrencyPrice';
  
  export default function reducer(
    state = {
      entities: [],
      fetching: false,
      currentCurrency: null,
      currentDateFrom: null,
      currentDateTo: null,
    },
    action
  ) {
    switch (action.type) {
      case GET_ADMIN_CURRENCY_PRICE_REQUEST:
        return {
          ...state,
          fetching: true,
        };
      case  GET_ADMIN_CURRENCY_PRICE_SUCCESS:
        return {
          ...state,
          entities:[...action.payload.entities],
          currentCurrency: action.payload.currency,
          currentDateFrom:action.payload.from,
          currentDateTo: action.payload.to,
          fetching: false,
        };
      default:
        return state;
    }
  }