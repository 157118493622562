import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsErrorShow } from './notifications';

// CONSTANTS
export const CURRENCIES_GET = 'CURRENCIES_GET';
export const CURRENCIES_SET = 'CURRENCIES_SET';
export const CURRENCIES_ERROR = 'CURRENCIES_ERROR';

// ACTIONS GENERATORS
export const currenciesGet = () => ({
  type: CURRENCIES_GET,
  fetching: true,
});

export const currenciesSet = data => ({
  type: CURRENCIES_SET,
  fetching: false,
  payload: data,
});

export const currenciesError = () => ({
  type: CURRENCIES_ERROR,
});

// ASYNC ACTIONS
export const getCurrencies = dispatch => async merchantId => {
  dispatch(currenciesGet());
  let currencies;

  try {
    currencies = await cryptoApi.getCurrencies(merchantId);
  } catch (err) {
    dispatch(currenciesError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (currencies && currencies.data) {
    dispatch(currenciesSet({ ...currencies.data }));
    return;
  }

  dispatch(currenciesError());
};
