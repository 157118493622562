import {
  GET_ADMIN_MARKETING_INFO_REQUEST,
  GET_ADMIN_MARKETING_INFO_SUCCESS,
  GET_ADMIN_MARKETING_INFO_ERROR,
} from '../../actions/adminPanel/adminMarketingInfoDownload';

export default function reducer(
  state = {
    fetching: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case GET_ADMIN_MARKETING_INFO_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_ADMIN_MARKETING_INFO_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case GET_ADMIN_MARKETING_INFO_ERROR:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
}
