import {
  LOGIN,
  LOGIN_SIGN_IN,
  LOGIN_SOFT_SIGN_IN,
  LOGIN_SIGN_OUT,
  LOGIN_SET_APP_TWO_FA,
  LOGIN_SET_EMAIL_TWO_FA,
  LOGIN_SHOW_PHONE_CODE_VERIFY,
  LOGIN_ERROR,
  LOGIN_GOOGLE,
  LOGIN_METAMASK
} from '../actions/login';

export default function reducer(
  state = {
    data: null,
    isAppTwoFa: false,
    isEmailTwoFa: false,
    loginShowPhoneCodeVerify: false,
    fetching: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case LOGIN:
    case LOGIN_GOOGLE:
    case  LOGIN_METAMASK:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case LOGIN_SIGN_IN:
    case LOGIN_SOFT_SIGN_IN:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        error: null,
      };
    case LOGIN_SET_APP_TWO_FA:
      return {
        ...state,
        isAppTwoFa: action.payload,
        fetching: false,
        error: null,
      };
    case LOGIN_SET_EMAIL_TWO_FA:
      return {
        ...state,
        isEmailTwoFa: action.payload,
        fetching: false,
        error: null,
      };
    case LOGIN_SHOW_PHONE_CODE_VERIFY:
      return {
        ...state,
        loginShowPhoneCodeVerify: action.payload,
        fetching: false,
      };
    case LOGIN_SIGN_OUT:
      return {
        data: null,
        fetching: false,
        error: null,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
