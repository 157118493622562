import React from 'react';
import { Row, Col } from 'antd';

import ProfileEdit from '../components/ProfileEdit/ProfileEdit';

export default function Profile() {
  return (
    <React.Fragment>
      <Row>
        <Col span="24">
          <ProfileEdit />
        </Col>
      </Row>
    </React.Fragment>
  );
}
