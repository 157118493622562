import { adminApi } from '../../../service/admin-api';
import { notificationsErrorShow } from '../notifications';
import moment from 'moment';

// GET CURRENCY PRICE STATISTICS

export const GET_ADMIN_CURRENCY_PRICE_REQUEST = 'GET_ADMIN_CURRENCY_PRICE_REQUEST';
export const GET_ADMIN_CURRENCY_PRICE_SUCCESS = 'GET_ADMIN_CURRENCY_PRICE_SUCCESS';

export const getCurrencyPriceRequest = () => ({
  type: GET_ADMIN_CURRENCY_PRICE_REQUEST,
});

export const getCurrencyPriceSuccess = payload => ({
  type: GET_ADMIN_CURRENCY_PRICE_SUCCESS,
  payload,
});

export const getCurrencyPriceById =
  (body = {}) =>
  async dispatch => {
    dispatch(getCurrencyPriceRequest());

    try {
      const response = await adminApi.getCurrencyPriceById(body.currencyId, {
        ...body,
        dateForFiltering: undefined,
        currencyId: undefined,
        currencyTitle: undefined,
        dateFrom:
          body.dateForFiltering && body.dateForFiltering[0]
            ? moment(body.dateForFiltering[0]).format('YYYY-MM-DD') + 'T00:00:01'
            : undefined,
        dateTo:
          body.dateForFiltering && body.dateForFiltering[1]
            ? moment(body.dateForFiltering[1]).format('YYYY-MM-DD') + 'T23:59:59'
            : undefined,
      });

      dispatch(
        getCurrencyPriceSuccess({
          entities: [...response.data],
          currency: body.currencyTitle,
          from: moment(body.dateForFiltering[0]).format('DD-MM-YYYY'),
          to: moment(body.dateForFiltering[1]).format('DD-MM-YYYY'),

        })
      );
    } catch (error) {
      dispatch(notificationsErrorShow(error.data));
    }
  };
