import alfacashIcon from '../img/swapPartners/alfacash.png';
import binanceIcon from '../img/swapPartners/binance.png';
import changenowIcon from '../img/swapPartners/changenow.png';
import exolixIcon from '../img/swapPartners/exolix.png';
import fixedfloatIcon from '../img/swapPartners/fixedfloat.png';
import godexIcon from '../img/swapPartners/godex.png';
import instaswapIcon from '../img/swapPartners/instaswap.png';
import simpleswapIcon from '../img/swapPartners/simpleswap.png';
import stealthexIcon from '../img/swapPartners/stealthex.png';
import swapuzIcon from '../img/swapPartners/swapuz.png';
import switchainIcon from '../img/swapPartners/switchain.png';
import letsexchangeIcon from '../img/swapPartners/letsexchange.png';
import changeheroIcon from '../img/swapPartners/changehero.png';
import changellyIcon from '../img/swapPartners/changelly.png';
import quickexIcon from '../img/swapPartners/quickex.png';
import kucoinIcon from '../img/swapPartners/kucoin.png';
import swapleIcon from '../img/swapPartners/swaple.png';
import defaultIcon from '../img/swapPartners/default.png';

const partnerIcons = {
  alfacash: alfacashIcon,
  binance: binanceIcon,
  changenow: changenowIcon,
  exolix: exolixIcon,
  fixedfloat: fixedfloatIcon,
  godex: godexIcon,
  instaswap: instaswapIcon,
  simpleswap: simpleswapIcon,
  stealthex: stealthexIcon,
  swapuz: swapuzIcon,
  switchain: switchainIcon,
  letsexchange: letsexchangeIcon,
  changehero: changeheroIcon,
  changelly: changellyIcon,
  quickex: quickexIcon,
  kucoin: kucoinIcon,
  swaple: swapleIcon,
  default: defaultIcon,
};

const getSwapPartnerIcon = partnerName => partnerIcons[partnerName] || partnerIcons.default;

export default getSwapPartnerIcon;
