import { adminApi } from '../../../service/admin-api';
import { notificationsErrorShow } from '../notifications';

// GET SYSTEM FEE TRANSACTIONS

export const GET_ADMIN_FEE_TRANSACTIONS_REQUEST = 'GET_ADMIN_FEE_TRANSACTIONS_REQUEST';
export const GET_ADMIN_FEE_TRANSACTIONS_SUCCESS = 'GET_ADMIN_FEE_TRANSACTIONS_SUCCESS';
export const GET_ADMIN_FEE_TRANSACTIONS_MORE_REQUEST = 'GET_ADMIN_FEE_TRANSACTIONS_MORE_REQUEST';
export const GET_ADMIN_FEE_TRANSACTIONS_MORE_SUCCESS = 'GET_ADMIN_FEE_TRANSACTIONS_MORE_SUCCESS';

export const getAdminFeeTransactionsRequest = () => ({
  type: GET_ADMIN_FEE_TRANSACTIONS_REQUEST,
});

export const getAdminFeeTransactionsSuccess = payload => ({
  type: GET_ADMIN_FEE_TRANSACTIONS_SUCCESS,
  payload,
});

export const getAdminFeeTransactionsMoreRequest = () => ({
  type: GET_ADMIN_FEE_TRANSACTIONS_MORE_REQUEST,
});

export const getAdminFeeTransactionsMoreSuccess = payload => ({
  type: GET_ADMIN_FEE_TRANSACTIONS_MORE_SUCCESS,
  payload,
});

export const getFeeTransactions =
  (body = {}) =>
  async dispatch => {
    dispatch(getAdminFeeTransactionsRequest());

    try {
      const response = await adminApi.getFeeTransactions({ ...body, limit: 5 });
      dispatch(getAdminFeeTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(notificationsErrorShow(error.data));
    }
  };

export const getMoreFeeTransactions =
  (body = {}) =>
  async dispatch => {
    dispatch(getAdminFeeTransactionsMoreRequest());

    try {
      const response = await adminApi.getFeeTransactions({ ...body, limit: 5 });
      dispatch(getAdminFeeTransactionsMoreSuccess(response.data));
    } catch (error) {
      dispatch(notificationsErrorShow(error.data));
    }
  };
