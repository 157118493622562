import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PublicRoute = ({ userData, isShowWelcomeModal, children, location, ...rest }) => {
  const query = useQuery();
  const { data } = userData;

  return data ? (
    <Redirect
      to={
        data && data.roles[0] === 'admin'
          ? '/admin'
          : isShowWelcomeModal
          ? `/registration-completed${query.get('lang') ? `?lang=${query.get('lang')}` : ''}`
          : `/${query.get('lang') ? `?lang=${query.get('lang')}` : ''}`
      }
    />
  ) : (
    <Route {...rest}>{children}</Route>
  );
};
const mapStateToProps = state => ({
  userData: state.login,
  isShowWelcomeModal: state.user.showWelcomeModal,
});

export default connect(mapStateToProps)(withRouter(PublicRoute));
