import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsErrorShow } from './notifications';

// CONSTANTS
export const MAIN_WALLETS_GET = 'MAIN_WALLETS_GET';
export const MAIN_WALLETS_SET = 'MAIN_WALLETS_SET';
export const MAIN_WALLETS_ERROR = 'MAIN_WALLETS_ERROR';

// ACTIONS GENERATORS
const mainWalletsGet = () => ({
  type: MAIN_WALLETS_GET,
});
const mainWalletsSet = data => ({
  type: MAIN_WALLETS_SET,
  payload: data,
});
const mainWalletsError = () => ({
  type: MAIN_WALLETS_ERROR,
});

// ASYNC ACTIONS
export const getMainWallets = dispatch => async merchantId => {
  dispatch(mainWalletsGet());
  let wallets;

  try {
    wallets = await cryptoApi.getMainWallets(merchantId);
  } catch (err) {
    dispatch(mainWalletsError());
    dispatch(notificationsErrorShow(err.data));
  }

  if (wallets && wallets.data) {
    dispatch(mainWalletsSet([...wallets.data]));
    return;
  }

  dispatch(mainWalletsError());
};
